import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 320.000000 266.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,266.000000) scale(0.100000,-0.100000)">
   <path d="M818 1979 l-3 -681 -200 -69 c-412 -142 -525 -182 -521 -186 8 -8
822 218 1161 322 83 25 268 84 411 131 248 80 262 86 262 109 0 14 -5 25 -10
25 -6 0 -8 -9 -5 -20 3 -11 2 -20 -3 -20 -4 0 -10 11 -12 25 -3 13 -2 23 1 20
4 -2 15 11 25 28 l18 32 -38 -38 c-63 -63 -134 -79 -356 -84 l-188 -5 0 307 0
306 203 -4 c111 -2 213 -7 227 -10 14 -4 8 -1 -13 6 -21 7 -35 16 -31 19 8 9
-25 146 -37 154 -5 3 -9 18 -9 34 0 16 -6 47 -14 67 -8 21 -14 45 -14 53 -1
21 -35 114 -44 118 -5 2 -8 12 -8 23 0 18 -12 19 -400 19 l-400 0 -2 -681z"/>
<path d="M1820 2156 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M2868 1675 c-323 -87 -659 -183 -928 -266 -247 -77 -688 -224 -735
-246 l-30 -14 32 5 c28 5 31 3 28 -15 -2 -11 1 -32 7 -47 6 -15 10 -32 9 -38
-1 -7 4 -25 10 -40 16 -38 16 -38 19 -81 2 -35 3 -36 10 -13 9 29 11 24 14
-50 2 -29 8 -56 15 -58 6 -2 11 -16 11 -30 0 -15 7 -47 14 -72 14 -42 15 -25
15 208 l1 254 233 -4 c219 -3 235 -5 280 -27 101 -49 142 -137 142 -301 0 -98
-2 -109 -30 -159 -30 -54 -61 -83 -125 -117 -30 -16 -64 -19 -251 -22 -159 -3
-216 -7 -215 -15 2 -7 9 -25 16 -42 6 -16 13 -42 15 -58 1 -15 5 -30 9 -34 3
-3 6 -17 6 -30 0 -12 4 -23 8 -23 5 0 21 -53 37 -117 16 -65 33 -123 38 -130
5 -7 5 -18 1 -24 -5 -9 37 -11 187 -6 205 7 292 20 409 61 201 70 342 221 402
431 20 69 23 103 23 255 0 145 -3 184 -19 230 -51 151 -136 255 -256 313 l-70
34 47 29 c27 17 176 73 333 127 329 111 562 196 540 196 -8 0 -122 -29 -252
-64z m-1443 -1155 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z"/>
<path d="M1940 1600 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
